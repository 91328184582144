<template>
  <div class="not-found-page row justify-content-center align-items-center">
    <div class="col-8 text-center">
      <h2 class="not-found-title my-4">404</h2>
      <h2 class="not-found-desc my-4">
        Oops, this page does not seemed to exist
      </h2>
      <button class="btn main go-home" @click="$router.push({ name: 'Entry' })">
        Go Home
      </button>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  mixins: [],
  props: {},
  data: function () {
    return {};
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {},
  methods: {}
};
</script>

<style lang="scss">
.not-found-page {
  min-height: 100vh;
  background: url("~@/assets/image/illustration/lost.svg");
  background-repeat: no-repeat;
  background-size: 100vh;
  background-position: right -5% bottom -10%;
  .not-found-title {
    font-size: 15em;
    text-shadow: 0px 5px 10px #00000025;
  }
  .not-found-desc {
    font-size: 2.5em;
  }
  .picture {
    width: 15em;
    height: 15em;
  }
  .go-home {
    font-size: 1.8em;
    padding: 10px 25px;
    box-shadow: 0px 5px 10px -5px $color-main;
  }
}
</style>
